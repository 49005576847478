<template>
  <div>
    <v-row class="mx-0">
      <v-col cols="12" class="pa-0">
        <v-card class="rounded-b-xl" elevation="2">
          <v-list>
            <v-list-item v-for="document in documents" :key="document.id" @click="()=>{}" style="min-height:36px !important">
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-medium blue-grey--text darken-4" @click="downloadFile(document)"> {{ document.title ? document.title : document.original_name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="pa-0 ma-0">
                <v-layout row wrap class="mx-0">
                  <v-btn small icon class="pa-0 ma-0" @click="downloadFile(document)">
                    <v-icon v-if="document.is_url==1" color="primary lighten-1">mdi-link</v-icon>
                    <v-icon v-else color="primary lighten-1">mdi-download</v-icon>
                  </v-btn>
                  <v-btn v-if="role.name === 'Administrator'" small icon class="pa-0 ma-0" @click="deleteFile(document)" :disabled="preventFileDelete">
                    <v-icon color="error lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-layout>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
        <v-dialog v-model="showPdfPreviewDialog" scrollable fullscreen>
          <v-card>
            <v-card-title class="pa-0">
              <v-toolbar dense dark color="primary">
              <v-btn icon dark @click="showPdfPreviewDialog = false; activePdfLink=''">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ pdftitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px;" class="pa-0">
              <pdf-viewer v-if="showPdfPreviewDialog" :fileUrl="activePdfLink"></pdf-viewer>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showImageInFullScreen" scrollable fullscreen>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn fab elevation="0" class="white">
              <v-icon large @click="showImageInFullScreen = false">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-img v-if="showImageInFullScreen" :src="selectedImage"></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    deleteIds: {
      type: Array,
      default: () => []
    },
    preventFileDelete: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      MODULE: 'uploaded_documents',
      showPdfPreviewDialog: false,
      iframeSrcLoading: false,
      activePdfLink: '',
      pdftitle: '',
      showImageInFullScreen: false,
      selectedImage: false,
      role: JSON.parse(window.localStorage.getItem('UserRole'))
    }
  },
  created () {},
  mounted () {
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteSuccess', ({ document, activeModule }) => {
      if (activeModule === this.MODULE) {
        this.documents.splice(this.documents.indexOf(document), 1)
        this.deleteIds.push(document.id)
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
      }
    })
  },
  components: {
    'pdf-viewer': () => import('@/components/PdfViewer/PdfViewer.vue')
  },
  methods: {
    downloadFile (document) {
      const extentions = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
      if (document.downloadUrl && document.extention === 'pdf') {
        this.showPdfInIFrame(document)
      } else if (document.downloadUrl && extentions.includes(document.extention)) {
        this.showImageInIFrame(document)
      } else if (document.downloadUrl) {
        window.open(document.downloadUrl, '_blank')
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.urlNotFound.urlNotFound' })
      }
    },
    deleteFile (document) {
      const payload = { isDocumentDelete: true, document, activeModule: this.MODULE } // it is local delete
      this.$store.commit('showDeleteDialog', payload)
    },
    showPdfInIFrame (item) {
      this.activePdfLink = item.downloadUrl
      this.iframeSrcLoading = true
      this.showPdfPreviewDialog = true
      this.pdftitle = item.original_name
    },
    showImageInIFrame (item) {
      this.selectedImage = item.downloadUrl
      this.showImageInFullScreen = true
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
  }
}
</script>

<style>

</style>
